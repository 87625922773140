import React from "react";

import { AddIntakeDialog } from "features/add-calorie-intake";

import styles from "./calories-calendar.module.scss";

export function CaloriesCalendar() {
  return (
    <div className={styles.calendar}>
      <AddIntakeDialog />
    </div>
  );
}
