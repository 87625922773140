import React from "react";
import invariant from "tiny-invariant";

import { TaskModel, setTaskPlan } from "entities/tasks";
import styles from "entities/tasks/ui/task-settings.module.scss";

import { RecurringPlan } from "shared/database";
import { Tags } from "shared/ui";
import { RadioInput } from "shared/ui/radio-input";
import { TextInput } from "shared/ui/text-input";

interface Props {
  task: TaskModel;
}

export function TaskPlanRadioInput({ task }: Props) {
  const [rRule, setRRule] = React.useState<string | null>(
    task.task.plan?.type === "recurring" ? task.task.plan.rrule : null,
  );

  return (
    <>
      {" "}
      <RadioInput<"notPlanned" | "flexible" | "recurring">
        label="Планирование"
        value={task.task.plan?.type ?? "notPlanned"}
        onChange={async (value) => {
          invariant(task);

          if (value === "notPlanned" || value === "flexible") {
            await setTaskPlan(task.task, { type: value });
          } else {
            await setTaskPlan(task.task, {
              type: value,
              rrule: "RRULE:FREQ=DAILY;WKST=MO",
              groupType: "day",
            });
          }
        }}
      >
        <RadioInput.Option value="notPlanned">Не планировать</RadioInput.Option>
        <RadioInput.Option value="flexible">Гибкое</RadioInput.Option>
        <RadioInput.Option value="recurring">Повторяющееся</RadioInput.Option>
      </RadioInput>
      {task.task.plan?.type === "recurring" && rRule !== null && (
        <div className={styles.recurringOptions}>
          <TextInput
            label="RRule"
            value={rRule}
            onChange={(value) => {
              invariant(task?.task.plan?.type === "recurring");
              setRRule(value.trim());
            }}
            onBlur={async () => {
              invariant(task?.task.plan?.type === "recurring");
              await setTaskPlan(task.task, {
                type: "recurring",
                groupType: task.task.plan.groupType,
                rrule: rRule,
              });
            }}
          />
          <Tags<RecurringPlan["groupType"]>
            value={task.task.plan.groupType}
            onChange={async (value) => {
              invariant(task?.task.plan?.type === "recurring");

              await setTaskPlan(task.task, {
                type: "recurring",
                rrule: task.task.plan.rrule,
                groupType: value,
              });
            }}
          >
            <Tags.Tag value="day">День</Tags.Tag>
            <Tags.Tag value="week">Неделя</Tags.Tag>
            <Tags.Tag value="month">Месяц</Tags.Tag>
            <Tags.Tag value="year">Год</Tags.Tag>
          </Tags>
        </div>
      )}
    </>
  );
}
