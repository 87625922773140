import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  ElementDragPayload,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { DragLocationHistory } from "@atlaskit/pragmatic-drag-and-drop/types";
import { clsx } from "clsx";
import { useLiveQuery } from "dexie-react-hooks";
import { motion, useMotionValue } from "framer-motion";
import { DateTime } from "luxon";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { merge, range } from "remeda";
import invariant from "tiny-invariant";

import { useEvents } from "entities/events";
import { TaskModel, getAllTasks } from "entities/tasks";
import { useZones } from "entities/zones/model/use-zones";

import { EventEntity, ZoneEntity, database } from "shared/database";
import { roundMinutesToSlot } from "shared/libs/time";

import styles from "./calendar-week.module.scss";

import { Event } from "./event";
import { EventPreview } from "./event-preview";

interface WeekDaysProps {
  startDay: DateTime;
  days: number;
}

function WeekDays({ startDay, days }: WeekDaysProps) {
  return (
    <div className={styles.weekDays}>
      {range(0, days).map((dayIndex) => {
        const day = startDay.plus({ day: dayIndex });

        return (
          <div
            key={day.toISO()}
            className={clsx(styles.day, {
              [styles.isCurrent]: DateTime.now().startOf("day").equals(day),
            })}
          >
            {day.toLocaleString(
              { weekday: "short", day: "2-digit" },
              { locale: "ru" },
            )}
          </div>
        );
      })}
    </div>
  );
}

interface HeaderProps {
  startDay: DateTime;
  days: number;
}

function Header({ startDay, days }: HeaderProps) {
  return (
    <div
      className={styles.header}
      style={{
        gridTemplateColumns: `[time-start] var(--time-column-width) [time-end week-start] repeat(${days}, [day-start] 1fr [day-end]) [week-end]`,
      }}
    >
      <div className={styles.timeColumn} />
      <WeekDays startDay={startDay} days={days} />
    </div>
  );
}

function TimeColumn({ today }: { today: DateTime }) {
  return (
    <div className={styles.timeColumn}>
      {range(0, 24).map((hour) => (
        <span key={hour} className={styles.hour}>
          {today.plus({ hour }).toLocaleString(DateTime.TIME_24_SIMPLE)}
        </span>
      ))}
    </div>
  );
}

interface GridProps {
  days: number;
}

function Grid({ days }: GridProps) {
  return (
    <div className={styles.grid}>
      <div className={styles.days}>
        {range(0, days).map((dayIndex) => (
          <div key={dayIndex} className={styles.day} />
        ))}
      </div>
      <div className={styles.hours}>
        {range(0, 26).map((hourIndex) => (
          <div key={hourIndex} className={styles.hour} />
        ))}
      </div>
    </div>
  );
}

function CurrentTimeDayBar({ height }: { height: number }) {
  const currentHeight = useMotionValue(estimateCurrentTimeBarHeight(height));

  useEffect(() => {
    const intervalId = setInterval(
      () => currentHeight.set(estimateCurrentTimeBarHeight(height)),
      1000,
    );

    return () => clearInterval(intervalId);
  }, [currentHeight, height]);

  return (
    <div className={styles.dayNowContainer}>
      <motion.div className={styles.dayNow} style={{ y: currentHeight }} />
    </div>
  );
}

interface DayEventsProps {
  startDay: DateTime;
  dayIndex: number;
  events: EventEntity[];
  height: number;
  tasks: Map<string, TaskModel>;
  zones: ZoneEntity[];
}

function DayEvents({
  startDay,
  dayIndex,
  events,
  height,
  tasks,
  zones,
}: DayEventsProps) {
  const [dayElement, setDayElement] = useState<HTMLDivElement | null>(null);
  const [previewDetails, setPreviewDetails] = useState<{
    startDateTime: DateTime;
    actualDuration: number;
  } | null>(null);

  const { pin } = useEvents();

  const day = startDay.plus({ day: dayIndex });

  const estimatePreviewDetails = useCallback(
    (location: DragLocationHistory, source: ElementDragPayload) => {
      const data: {
        calendarTop: number;
        calendarScrollTop: number;
        eventPreviewTopOffset: number;
        cursorY: number;
      } = location.current.dropTargets
        .map((t) => t.data)
        .reduce((p, c) => merge(p, c)) as never;

      const dayTop =
        data.calendarScrollTop +
        (data.cursorY - data.calendarTop) +
        -data.eventPreviewTopOffset;

      const dayMinutes = (dayTop / height) * 60 * 24;
      const roundedDayMinutes = Math.floor(dayMinutes / 15) * 15;

      return {
        startDateTime: day.plus({ minute: roundedDayMinutes }),
        actualDuration: source.data.actualDuration as number,
        estimatedDuration: source.data.estimatedDuration as number,
      };
    },
    [day, height],
  );

  useEffect(() => {
    if (!dayElement) return;

    return dropTargetForElements({
      element: dayElement,
      getData: ({ input }) => ({ cursorY: input.clientY }),
      onDrag: ({ location, source }) => {
        setPreviewDetails(estimatePreviewDetails(location, source));
      },
      onDragLeave: () => setPreviewDetails(null),
      onDrop: ({ location, source }) => {
        const previewDetails = estimatePreviewDetails(location, source);

        pin(
          source.data.id as never,
          previewDetails.startDateTime,
          previewDetails.estimatedDuration,
        );

        setPreviewDetails(null);
      },
    });
  }, [day, dayElement, estimatePreviewDetails, height, pin]);

  const eventLines = useMemo(
    function evaluateEventLines() {
      const eventLines = new Map<
        EventEntity,
        { line: number; total: number }
      >();
      const lastListEvents = new Map<number, EventEntity>();

      const sortedEvents = events.sort((a, b) => {
        if (a.startDate.getTime() !== b.startDate.getTime())
          return a.startDate.getTime() - b.startDate.getTime();

        const aDuration = a.actualDuration ?? a.duration;
        const bDuration = b.actualDuration ?? b.duration;
        if (aDuration !== bDuration) return bDuration - aDuration;

        const taskA = tasks.get(a.taskId);
        invariant(taskA);

        const taskB = tasks.get(b.taskId);
        invariant(taskB);

        return taskA.title.localeCompare(taskB.title);
      });

      for (const event of sortedEvents) {
        const eventStartDateTime = DateTime.fromJSDate(event.startDate);

        const freeLine = Array.from(lastListEvents.entries()).find(
          ([, lastEvent]) =>
            DateTime.fromJSDate(lastEvent.startDate).plus({
              minute: roundMinutesToSlot(
                lastEvent.actualDuration ?? lastEvent.duration,
              ),
            }) <= eventStartDateTime,
        );

        const freeLineNumber = freeLine ? freeLine[0] : lastListEvents.size;

        lastListEvents.set(freeLineNumber, event);

        Array.from(lastListEvents.entries())
          .filter(([line]) => line <= freeLineNumber)
          .forEach(([line, event]) => {
            eventLines.set(event, {
              line,
              total: Math.max(
                freeLineNumber,
                eventLines.get(event)?.total ?? 0,
              ),
            });
          });
      }

      return eventLines;
    },
    [events, tasks],
  );

  return (
    <div
      className={styles.day}
      ref={setDayElement}
      style={{
        gridColumn: `day-start ${dayIndex + 1} / day-end ${dayIndex + 1}`,
      }}
    >
      {events.map((event) => {
        const task = tasks.get(event.taskId);
        if (!task) {
          console.error(`Unable to find task for event with id ${event.id}`);
          return;
        }

        const zone = zones.find((z) => z.id === task.zoneId);

        return (
          <Event
            key={event.id}
            event={event}
            task={task}
            zone={zone}
            height={height}
            parallelEvents={(eventLines.get(event)?.total ?? 0) + 1}
            parallelEventIndex={eventLines.get(event)?.line ?? 0}
          />
        );
      })}
      {DateTime.now().startOf("day").equals(day) && (
        <CurrentTimeDayBar height={height} />
      )}
      {previewDetails && (
        <EventPreview
          startDateTime={previewDetails.startDateTime}
          duration={previewDetails.actualDuration}
          height={height}
        />
      )}
    </div>
  );
}

function CurrentTimeWeekBar({ height }: { height: number }) {
  const currentHeight = useMotionValue(estimateCurrentTimeBarHeight(height));
  const lineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const intervalId = setInterval(
      () => currentHeight.set(estimateCurrentTimeBarHeight(height)),
      1000,
    );

    return () => clearInterval(intervalId);
  }, [currentHeight, height]);

  useEffect(() => {
    lineRef.current?.scrollIntoView({
      block: "center",
      behavior: "instant",
    });
  }, [lineRef]);

  return (
    <div className={styles.weekNowContainer}>
      <motion.div
        ref={lineRef}
        className={styles.weekNow}
        style={{ y: currentHeight }}
      />
    </div>
  );
}

function estimateCurrentTimeBarHeight(height: number) {
  const currentMinuteOfTheDay = DateTime.now().diff(
    DateTime.now().startOf("day"),
    "minutes",
  ).minutes;

  return (currentMinuteOfTheDay / 60 / 24) * height;
}

interface ScheduleProps {
  startDay: DateTime;
  days: number;
}

function Schedule({ startDay, days }: ScheduleProps) {
  const { events, tasks } = useLiveQuery(
    async () => {
      return {
        events: await database.events.toCollection().sortBy("startDate"),
        tasks: await getAllTasks(),
      };
    },
    [],
    { events: null, tasks: null },
  );

  const { zones } = useZones();

  const [scheduleElement, setScheduleElement] = useState<HTMLDivElement | null>(
    null,
  );

  const [today, setToday] = useState<DateTime>(DateTime.now().startOf("day"));

  useEffect(
    function refreshWeekOnDayChange() {
      const intervalId = setInterval(() => {
        const actualToday = DateTime.now().startOf("day");
        if (!today.equals(actualToday)) setToday(actualToday);
      }, 1000);
      return () => clearInterval(intervalId);
    },
    [today],
  );

  useEffect(() => {
    if (!scheduleElement) return;

    return combine(
      autoScrollForElements({
        element: scheduleElement,
      }),
      dropTargetForElements({
        element: scheduleElement,
        getData: ({ source }) => ({
          calendarTop: scheduleElement.getBoundingClientRect().top,
          calendarScrollTop: scheduleElement.scrollTop,
          eventPreviewTopOffset: source.data.previewTopOffset,
        }),
      }),
    );
  }, [scheduleElement]);

  if (!tasks || !zones || !events) return;

  const height = 2000;

  return (
    <div className={styles.schedule} ref={setScheduleElement}>
      <div
        className={styles.container}
        style={{
          gridTemplateColumns: `[time-start] var(--time-column-width) [time-end week-start] repeat(${days}, [day-start] 1fr [day-end]) [week-end]`,
          height,
        }}
      >
        <Grid days={days} />
        <TimeColumn today={today} />
        {range(0, days).map((dayIndex) => {
          const day = startDay.startOf("day").plus({ day: dayIndex });
          return (
            <DayEvents
              key={day.toISO()}
              startDay={startDay}
              dayIndex={dayIndex}
              events={events.filter((tb) =>
                DateTime.fromJSDate(tb.startDate).startOf("day").equals(day),
              )}
              height={height}
              tasks={new Map(tasks.map((t) => [t.id, t]))}
              zones={zones}
            />
          );
        })}
        <CurrentTimeWeekBar height={height} />
      </div>
    </div>
  );
}

interface CalendarWeekProps {
  startDay: DateTime;
  days: number;
}

export function CalendarWeek({ startDay, days }: CalendarWeekProps) {
  return (
    <div className={styles.week}>
      <Header startDay={startDay} days={days} />
      <Schedule startDay={startDay} days={days} />
    </div>
  );
}
