import React from "react";
import { RouteObject } from "react-router-dom";

import { MealsPage } from "pages/meals";
import { NotFoundPage } from "pages/not-found";
import { TasksPage } from "pages/tasks";

import { routePaths } from "shared/router";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <TasksPage />,
  },
  {
    path: routePaths.TASKS,
    element: <TasksPage />,
  },
  {
    path: routePaths.MEALS,
    element: <MealsPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];
