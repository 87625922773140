import { createContext } from "react";

import { TaskModel } from "entities/tasks/model/task-model";

interface TaskContextType {
  task?: TaskModel;
  isEditing?: boolean;
  onEditToggle?: () => void;
}

export const TaskContext = createContext<TaskContextType>({});
