import { useClickOutside, usePrevious } from "@react-hookz/web";
import { clsx } from "clsx";
import { Transition, motion } from "framer-motion";
import mergeRefs from "merge-refs";
import React, {
  ReactNode,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";

import styles from "./task-details.module.scss";

import { TaskContext } from "../model";

interface TaskDetailsProps {
  className?: string;
  isSelected: boolean;
  onSelect: () => void;
  isDragging: boolean;
  isNewTask?: boolean;
  onDelete: () => void;
  children: ReactNode;
}

export const TaskDetails = forwardRef<HTMLDivElement, TaskDetailsProps>(
  (
    {
      isSelected,
      onSelect,
      isDragging,
      isNewTask,
      className,
      onDelete,
      children,
    }: TaskDetailsProps,
    forwardedRef,
  ) => {
    const { task, onEditToggle, isEditing } = useContext(TaskContext);

    const prevIsEditing = usePrevious(isEditing);

    const ref = useRef<HTMLDivElement>(null);

    useClickOutside(ref, () => isEditing && onEditToggle?.());

    useEffect(() => {
      if (isSelected && !isEditing) ref.current?.focus();
    }, [isSelected, isEditing]);

    const deleteHotkeyRef = useHotkeys<HTMLDivElement>(
      ["Delete", "Backspace"],
      onDelete,
      {
        preventDefault: true,
      },
    );

    if (!task) return;

    const transition: Transition = {
      duration: 0.4,
      ease: "circOut",
      backgroundColor: {
        ease: "circOut",
        duration: prevIsEditing !== isEditing ? 0.4 : 0,
      },
    };

    const currentVariant = isEditing
      ? "editing"
      : isSelected
        ? "selected"
        : "default";

    return (
      <motion.div
        ref={mergeRefs(ref, forwardedRef, deleteHotkeyRef)}
        tabIndex={0}
        layout="position"
        style={{ borderRadius: 8 }}
        className={styles.details}
        initial={isNewTask ? "default" : false}
        animate={currentVariant}
        variants={{
          default: {
            backgroundColor: "#fff",
            margin: 0,
            boxShadow: "none",
          },
          selected: {
            backgroundColor: "var(--selected-color, #cbe2ff)",
            margin: 0,
            boxShadow: "none",
          },
          editing: {
            backgroundColor: "#fff",
            margin: "2rem -0.5rem",
            boxShadow: "var(--secondary-color) 0 0 10px -2px",
          },
        }}
        transition={transition}
        onFocus={onSelect}
        onDoubleClick={() => !isEditing && onEditToggle?.()}
        onAnimationComplete={() => {
          if (isSelected && !isDragging)
            ref.current?.scrollIntoView({
              block: "nearest",
              behavior: "instant",
            });
        }}
        onLayoutAnimationComplete={() => {
          if (isSelected && !isDragging)
            ref.current?.scrollIntoView({
              block: "nearest",
              behavior: "instant",
            });
        }}
      >
        <motion.div
          transition={transition}
          variants={{
            default: { margin: 0 },
            selected: { margin: 0 },
            editing: { margin: "1rem 0.5rem 1rem" },
          }}
          className={clsx(styles.children, className)}
        >
          {children}
        </motion.div>
      </motion.div>
    );
  },
);

TaskDetails.displayName = "TaskDetails";
