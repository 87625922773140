import { useSet } from "@react-hookz/web";
import { useLiveQuery } from "dexie-react-hooks";
import { LayoutGroup } from "framer-motion";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import invariant from "tiny-invariant";
import { v4 as uuidv4 } from "uuid";

import { Group } from "features/priorities/ui/group";

import {
  getOutlineTasksWithPlans,
  resetTasksPriorities,
  updateTask,
} from "entities/tasks";
import { TaskModel } from "entities/tasks/model/task-model";
import { useZones } from "entities/zones/model/use-zones";

import { database } from "shared/database";

import styles from "./priorities.module.scss";

import { Task } from "./task";

export function Priorities() {
  const { api: zonesApi } = useZones();

  const forcefullySplitTasks = useSet<string>();

  const tasks = useLiveQuery(() => getOutlineTasksWithPlans());
  const prioritizedTasks = tasks
    ?.filter((t) => t.task.priority !== undefined)
    ?.sort((a, b) => a.task.priority! - b.task.priority!);

  const expandedPrioritizedTasks = (
    prioritizedTasks?.flatMap(expandTaskTree) ?? []
  ).concat(
    tasks
      ?.filter((t) => t.parent === null && t.task.priority === undefined)
      .flatMap(expandTaskTree) ?? [],
  );

  const priorityGroups = useLiveQuery(() =>
    database.priorityGroups.orderBy("order").toArray(),
  );

  useHotkeys(
    "Meta+N",
    async () => {
      await database.transaction(
        "readwrite",
        [database.priorityGroups],
        async () => {
          await database.priorityGroups.add({
            id: uuidv4(),
            name: "",
            order: -(priorityGroups?.length ?? 0) * 10000,
          });
        },
      );
    },
    { preventDefault: true },
  );

  if (!tasks || !priorityGroups) return;

  return (
    <LayoutGroup>
      <div className={styles.priorities}>
        {priorityGroups
          .filter((pg) => pg.order <= 0)
          .map((pg) => (
            <Group
              key={pg.id}
              id={pg.id}
              name={pg.name}
              onRename={async (name) =>
                await database.priorityGroups.update(pg.id, { name })
              }
              onDelete={async () => await database.priorityGroups.delete(pg.id)}
              isCollapsed={false}
              onCollapse={() => {}}
            >
              {expandedPrioritizedTasks
                .filter((t) => t.task.priorityGroupId === pg.id)
                .map(generateTask)}
            </Group>
          ))}
        <Group
          key="default"
          id="default"
          name="Стандартная"
          isDefault
          isCollapsed={false}
          onCollapse={() => {}}
        >
          {expandedPrioritizedTasks
            .filter((t) => !t.task.priorityGroupId)
            .map(generateTask)}
        </Group>
        {priorityGroups
          .filter((pg) => pg.order > 0)
          .map((pg) => (
            <Group
              key={pg.id}
              id={pg.id}
              name={pg.name}
              onRename={async (name) =>
                await database.priorityGroups.update(pg.id, { name })
              }
              onDelete={async () => await database.priorityGroups.delete(pg.id)}
              isCollapsed={false}
              onCollapse={() => {}}
            >
              {expandedPrioritizedTasks
                .filter((t) => t.task.priorityGroupId === pg.id)
                .map(generateTask)}
            </Group>
          ))}
      </div>
    </LayoutGroup>
  );

  function generateTask(task: TaskModel) {
    return (
      <Task
        key={task.id}
        task={task}
        zonesApi={zonesApi}
        onSplit={() => forcefullySplitTasks.add(task.id)}
        onMerge={async () => {
          if (task.task.priority !== undefined) {
            await updateTask(task.task, { priority: undefined });
            await resetTasksPriorities();
          } else {
            const parent = task.parent?.task;
            if (parent) forcefullySplitTasks.delete(parent.id);
          }
        }}
        onPrioritize={async (target, position) => {
          invariant(prioritizedTasks);

          const targetPriority = Math.max(
            0,
            getNearestParentPriority(target) ??
              (prioritizedTasks.length + 1) * 10000,
          );

          const minPriority =
            position === "before" ? targetPriority - 10000 : targetPriority;

          const maxPriority =
            position === "after" ? targetPriority + 10000 : targetPriority;

          await updateTask(task.task, {
            priority: (minPriority + maxPriority) / 2,
          });

          await resetTasksPriorities();

          function getNearestParentPriority(task: TaskModel) {
            if (task.task.priority !== undefined) return task.task.priority;
            if (!task.parent) return task.parent;
            return getNearestParentPriority(task.parent);
          }
        }}
      />
    );
  }

  function expandTaskTree(task: TaskModel): TaskModel[] {
    if (!forcefullySplitTasks.has(task.id) || task.children.length === 0)
      return [task];

    return task.children
      .sort((a, b) => a.order - b.order)
      .filter((c) => c.task.priority === undefined)
      .flatMap(expandTaskTree);
  }
}
