import React, { ReactNode } from "react";

import { Tags } from "shared/ui";

import { Option } from "./option";
import styles from "./radio-input.module.scss";

interface RadioInputProps<T> {
  label: string;
  value: T;
  onChange: (value: T) => void;
  children: ReactNode;
}

const RadioInput = <T,>({
  label,
  value,
  onChange,
  children,
}: RadioInputProps<T>) => {
  return (
    <div className={styles.input}>
      <label className={styles.label}>{label}</label>
      <div className={styles.control}>
        <Tags value={value} onChange={onChange}>
          {children}
        </Tags>
      </div>
    </div>
  );
};

export default Object.assign(RadioInput, {
  Option,
});
