import { DateTime } from "luxon";
import { useCallback } from "react";
import { entries, groupBy, mapValues, pipe, sum } from "remeda";

import { Scheduler } from "features/scheduler/model/scheduler";

import { clearEventsWithoutSessions, useEvents } from "entities/events";
import {
  getAllTasks,
  getOutlineTasks,
  scheduleTaskSessions,
} from "entities/tasks";
import { TaskModel } from "entities/tasks/model/task-model";
import { useZones } from "entities/zones/model/use-zones";

import { database } from "shared/database";
import { roundMinutesToSlot } from "shared/libs/time";

export function useScheduler() {
  const { replace } = useEvents();

  const { zones } = useZones();

  const schedule = useCallback(async () => {
    await database.transaction(
      "readwrite",
      [database.tasks, database.events],
      async () => {
        const tasksToUpdateSessions = await getOutlineTasks();
        await Promise.all(
          tasksToUpdateSessions.map((t) => scheduleTaskSessions(t.task)),
        );

        await clearEventsWithoutSessions();

        const allTasks = await getAllTasks();
        const schedulingTasks = allTasks.filter(
          (t) => t.task.location === "outline",
        );

        const beginningOfTheDaySlot = 8 * 4;
        const endingOfTheDaySlot = 24 * 4;

        const today = DateTime.now().startOf("day");
        const currentSlot = Math.round(
          Math.abs(today.diffNow().as("minutes") / 15),
        );

        const taskQueue = (
          schedulingTasks
            .filter((t) => t.task.priority !== undefined)
            .sort((a, b) => a.task.priority! - b.task.priority!)
            .flatMap(expandTaskTree) ?? []
        ).concat(
          schedulingTasks
            ?.filter((t) => t.parent === null && t.task.priority === undefined)
            ?.flatMap(expandTaskTree),
        );

        const sessions = taskQueue.flatMap((t) => t.uncompletedSessions);

        const events = await database.events.toArray();

        const scheduler = new Scheduler(
          sessions,
          zones.map((z) => {
            const zoneTaskIds = new Set(
              allTasks.filter((t) => t.zoneId === z.id).map((t) => t.id),
            );

            return {
              ...z,
              actualDayMinutes: new Map<string, number>(
                pipe(
                  events.filter(
                    (e) => e.actualDuration && zoneTaskIds.has(e.taskId),
                  ),
                  groupBy((e) => DateTime.fromJSDate(e.startDate).toISODate()!),
                  mapValues((g) => sum(g.map((e) => e.actualDuration!))),
                  entries(),
                ),
              ),
            };
          }),
          events.filter(
            (e) =>
              e.isPinned &&
              !e.actualDuration &&
              DateTime.fromJSDate(e.startDate).plus({
                minutes: roundMinutesToSlot(e.duration),
              }) > DateTime.now(),
          ),
        );

        const scheduledEvents = scheduler.schedule(
          currentSlot,
          beginningOfTheDaySlot,
          endingOfTheDaySlot,
        );

        await replace(
          scheduledEvents
            .filter((e) => e !== null)
            .map((e) => ({
              taskId: e.taskId,
              sessionId: e.sessionId,
              day: e.day,
              startMinute: e?.startMinute,
              endMinute: e?.endMinute,
              isPinned: e?.isPinned,
            })),
        );
      },
    );
  }, [replace, zones]);

  return { schedule };
}

function expandTaskTree(task: TaskModel): TaskModel[] {
  if (task.children.length === 0) return [task];

  return task.children
    .sort((a, b) => a.order - b.order)
    .filter((c) => c.task.priority === undefined)
    .flatMap(expandTaskTree);
}
