import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { clsx } from "clsx";
import { motion } from "framer-motion";
import { hyphenateSync as hyphenateEn } from "hyphen/en-us";
import { hyphenateSync as hyphenateRu } from "hyphen/ru";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { BsCheckSquare, BsSquare } from "react-icons/bs";
import invariant from "tiny-invariant";
import tinycolor from "tinycolor2";

import { completeEvent, useEvents } from "entities/events";
import { TaskModel } from "entities/tasks";

import {
  DefaultColor,
  EventEntity,
  Session,
  ZoneEntity,
} from "shared/database";
import { roundMinutesToSlot } from "shared/libs/time";

import styles from "./event.module.scss";

interface EventProps {
  event: EventEntity;
  task: TaskModel;
  zone?: ZoneEntity;
  height: number;
  parallelEvents: number;
  parallelEventIndex: number;
}

export function Event({
  task,
  zone,
  event,
  height,
  parallelEvents,
  parallelEventIndex,
}: EventProps) {
  const [expanderEarlierMinutes, setExpanderEarlierMinutes] = useState(0);
  const [expanderLaterMinutes, setExpanderLaterMinutes] = useState(0);

  const eventStartDateTime = DateTime.fromJSDate(event.startDate).minus({
    minutes: expanderEarlierMinutes,
  });

  const estimatedDuration = event.duration;

  const duration = Math.max(
    (event.actualDuration ?? estimatedDuration) +
      expanderEarlierMinutes +
      expanderLaterMinutes,
    15,
  );

  const day = eventStartDateTime.startOf("day");
  const startMinuteOfTheDay = eventStartDateTime.diff(day, "minutes").minutes;
  const endMinuteOfTheDay = startMinuteOfTheDay + duration;

  const color = tinycolor(zone ? zone.color : DefaultColor);

  const [eventElement, setEventElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!eventElement) return;

    return draggable({
      element: eventElement,
      getInitialData: ({ input }) => {
        return {
          type: "event",
          id: event.id,
          estimatedDuration,
          actualDuration: duration,
          previewTopOffset:
            input.clientY - eventElement.getBoundingClientRect().top,
        };
      },
    });
  }, [
    duration,
    endMinuteOfTheDay,
    estimatedDuration,
    event.id,
    eventElement,
    startMinuteOfTheDay,
  ]);

  const { pin } = useEvents();

  const session = task.task.plan?.sessions.find(
    (s) => s.id === event.sessionId,
  );
  invariant(session);

  const title = useMemo(
    () => evaluateTitle(task, session),
    [task.id, session.id],
  );

  return (
    <div
      className={styles.event}
      style={{
        top: `${(startMinuteOfTheDay / 60 / 24) * height}px`,
        left: `${(100.0 * parallelEventIndex) / parallelEvents}%`,
        height: `${(duration / 60 / 24) * height}px`,
        width: `${100.0 / parallelEvents}%`,
      }}
    >
      <div
        className={styles.container}
        ref={setEventElement}
        style={{
          backgroundColor: color
            .clone()
            .lighten(20)
            .setAlpha(0.8)
            .toHex8String(),
          color: color.clone().monochromatic(10)[5].toHexString(),
          borderLeft: `4px solid ${color.toHexString()}`,
        }}
      >
        <div className={styles.title}>
          <span className={styles.checkmark}>
            {event.actualDuration ? (
              <BsCheckSquare onClick={() => completeEvent(event, false)} />
            ) : (
              <BsSquare onClick={() => completeEvent(event, true)} />
            )}
          </span>
          <span title={title}>{title}</span>
        </div>
      </div>
      <motion.div
        className={clsx(styles.extender, styles.up)}
        onPan={(_, i) => {
          const offsetMinutes = roundMinutesToSlot(
            (Math.abs(i.offset.y) / height) * 60 * 24,
          );

          setExpanderEarlierMinutes(Math.sign(i.offset.y) * offsetMinutes * -1);
        }}
        onPanEnd={async () => {
          await pin(event.id, eventStartDateTime, duration);

          setExpanderEarlierMinutes(0);
        }}
      />
      <motion.div
        className={clsx(styles.extender, styles.down)}
        onPan={(_, i) => {
          const offsetMinutes = roundMinutesToSlot(
            (Math.abs(i.offset.y) / height) * 60 * 24,
          );

          setExpanderLaterMinutes(Math.sign(i.offset.y) * offsetMinutes);
        }}
        onPanEnd={async () => {
          await pin(event.id, eventStartDateTime, duration);

          setExpanderLaterMinutes(0);
        }}
      />
    </div>
  );
}

function evaluateTitle(task: TaskModel, session: Session) {
  if (!("startDate" in session)) return hyphenateEn(hyphenateRu(task.title));

  const sessionStartDate = DateTime.fromISO(session.startDate);
  const sessionEndDate = sessionStartDate.plus({ days: session.days - 1 });

  const localeOptions = { locale: "ru-RU" };
  const formattedStartDate = sessionStartDate.toFormat("d LLL", localeOptions);
  const formattedEndDate = sessionEndDate.toFormat("d LLL", localeOptions);

  const title =
    task.title +
    " – " +
    formattedStartDate +
    (session.days > 1 ? " / " + formattedEndDate : "");

  return hyphenateEn(hyphenateRu(title));
}
