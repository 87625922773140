import { clsx } from "clsx";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { IconType } from "react-icons";

import styles from "./control-panel.module.scss";

interface ButtonProps {
  icon: IconType;
  label?: string;
  hotkey?: string;
  onClick: () => void;
}

function Button({ icon: Icon, label, onClick, hotkey }: ButtonProps) {
  useHotkeys(hotkey ?? "", onClick, {
    preventDefault: true,
    enabled: !!hotkey,
  });

  return (
    <div className={styles.button} onClick={onClick}>
      <Icon />
      {label && <span>{label}</span>}
    </div>
  );
}

function Divider() {
  return <div className={styles.divider} />;
}

interface ControlPanelProps {
  children: React.ReactNode;
}

function ControlPanel({ children }: ControlPanelProps) {
  const [isOpen, setIsOpen] = useState(true);

  useHotkeys("h", () => setIsOpen(!isOpen));

  return (
    <div className={styles.container}>
      <motion.div
        className={clsx(styles.panel, { [styles.open]: isOpen })}
        animate={{
          y: isOpen ? 0 : 10,
          opacity: isOpen ? 1 : 0,
          visibility: isOpen ? "visible" : "hidden",
        }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Object.assign(ControlPanel, { Button, Divider });
