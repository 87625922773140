import React from "react";
import { BsXCircleFill } from "react-icons/bs";

import styles from "./not-found-page.module.scss";

export function NotFoundPage() {
  return (
    <div className={styles.page}>
      <BsXCircleFill className={styles.icon} />
    </div>
  );
}
