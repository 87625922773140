import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  COMMAND_PRIORITY_NORMAL,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  LineBreakNode,
} from "lexical";
import { useEffect } from "react";

export function PreventEnterPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerNodeTransform(LineBreakNode, (node: LineBreakNode) => {
        node.remove();
      }),
    [editor],
  );

  useEffect(
    () =>
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        () => true,
        COMMAND_PRIORITY_NORMAL,
      ),
    [editor],
  );

  return null;
}

interface OnEnterPluginProps {
  onExit: () => void;
}

export function OnExitPlugin({ onExit }: OnEnterPluginProps) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      () => {
        onExit();
        return true;
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [editor]);

  useEffect(() => {
    return editor.registerCommand(
      KEY_ESCAPE_COMMAND,
      () => {
        onExit();
        return true;
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [editor]);

  return null;
}
