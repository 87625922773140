import { useCallback, useEffect } from "react";

interface UseKeyboardNavigationProps {
  isTaskEditing?: boolean;
  onToggleTaskEditing?: () => void;
  onDisableTaskEditing?: () => void;
  onToggleTaskCompletion: () => void;
  onAddTask?: () => void;
  onIndent?: () => void;
  onUnindent?: () => void;
  onCollapse?: () => void;
  onExpand?: () => void;
}

export function useKeyboardNavigation({
  onAddTask,
  onIndent,
  onUnindent,
  onToggleTaskCompletion,
  isTaskEditing,
  onToggleTaskEditing,
  onDisableTaskEditing,
  onCollapse,
  onExpand,
}: UseKeyboardNavigationProps) {
  const handleKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      if (e.key === "ArrowRight" && !isTaskEditing && !e.metaKey) {
        e.preventDefault();
        onExpand?.();
      } else if (e.key === "ArrowLeft" && !isTaskEditing && !e.metaKey) {
        e.preventDefault();
        onCollapse?.();
      } else if (e.key === "." && e.metaKey) {
        e.preventDefault();
        onToggleTaskCompletion();
      } else if (e.key === " " && !isTaskEditing) {
        e.preventDefault();
        onAddTask?.();
      } else if (e.metaKey && (e.key === "[" || e.key === "]")) {
        e.preventDefault();
        if (e.key === "[") onUnindent?.();
        else onIndent?.();
      } else if (e.key === "Enter") {
        e.preventDefault();
        onToggleTaskEditing?.();
      } else if (e.key === "Escape") {
        e.preventDefault();
        onDisableTaskEditing?.();
      }
    },
    [
      isTaskEditing,
      onExpand,
      onCollapse,
      onToggleTaskCompletion,
      onAddTask,
      onUnindent,
      onIndent,
      onToggleTaskEditing,
      onDisableTaskEditing,
    ],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
}
