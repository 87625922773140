import { useLiveQuery } from "dexie-react-hooks";
import { useCallback } from "react";

import { ZoneEntity, database } from "shared/database";

export interface ZonesApi {
  tryFindZoneByName(name: string): ZoneEntity | null;
  tryGetById(id: string): ZoneEntity | null;
}

export function useZones() {
  const zones = useLiveQuery(
    () => database.zones.toArray(),
    [],
    [] as ZoneEntity[],
  );

  const tryFindZoneByName = useCallback(
    (name: string) => {
      return (
        zones.find((zone) =>
          zone.name.toLowerCase().includes(name.toLowerCase()),
        ) ?? null
      );
    },
    [zones],
  );

  const tryGetById = useCallback(
    (id: string) => {
      return zones.find((zone) => zone.id === id) ?? null;
    },
    [zones],
  );

  const api: ZonesApi = {
    tryFindZoneByName,
    tryGetById,
  };

  return { zones, api };
}
