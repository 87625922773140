import { exportDB } from "dexie-export-import";
import React, { useCallback } from "react";
import { BsCloudArrowDownFill } from "react-icons/bs";

import { database } from "shared/database";
import { ControlPanel } from "shared/ui/control-panel";

export function ExportButton() {
  const exportDatabase = useCallback(async () => {
    const blob = await exportDB(database);

    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.download = `plan-balance-backup-${new Date().toISOString()}.json`;
    a.href = blobUrl;
    a.click();

    URL.revokeObjectURL(blobUrl);
  }, []);

  return (
    <ControlPanel.Button icon={BsCloudArrowDownFill} onClick={exportDatabase} />
  );
}
