import React, { useContext } from "react";
import { BsCaretDownSquareFill, BsCaretRightSquareFill } from "react-icons/bs";

import styles from "./task-collapse.module.scss";

import { TaskContext, collapseTask, expandTask } from "../model";

export function TaskCollapse() {
  const { task } = useContext(TaskContext);
  if (!task || task.children.length === 0) return;

  return task.isCollapsed ? (
    <BsCaretRightSquareFill
      className={styles.collapse}
      onClick={async (e) => {
        e.stopPropagation();
        if (task) await expandTask(task.task);
      }}
      onDoubleClick={(e) => e.stopPropagation()}
    />
  ) : (
    <BsCaretDownSquareFill
      className={styles.collapse}
      onClick={async (e) => {
        e.stopPropagation();
        if (task) await collapseTask(task.task);
      }}
      onDoubleClick={(e) => e.stopPropagation()}
    />
  );
}
