import React from "react";

import { deleteZone, updateZone } from "entities/zones";

import { ZoneEntity } from "shared/database";
import { ColorInput } from "shared/ui/color-input";
import { Panel } from "shared/ui/panel";
import { TextInput } from "shared/ui/text-input";

import styles from "./zone-info.module.scss";

interface ZoneInfoProps {
  selectedZone: ZoneEntity;
}

export function ZoneInfo({ selectedZone }: ZoneInfoProps) {
  return (
    <Panel>
      <div className={styles.info}>
        <TextInput
          label="Название"
          value={selectedZone.name}
          onChange={async (name) => {
            await updateZone(selectedZone, { name });
          }}
        />
        <ColorInput
          label="Цвет"
          color={selectedZone.color}
          onChange={async (color) => {
            await updateZone(selectedZone, { color });
          }}
        />
        <TextInput
          label="Минуты в день"
          value={selectedZone.dayMinutesLimit?.toString() ?? ""}
          onChange={async (dayMinutesLimit) => {
            if (dayMinutesLimit === "")
              return await updateZone(selectedZone, { dayMinutesLimit: null });

            const parsedValue = Number.parseInt(dayMinutesLimit, 10);
            if (!Number.isFinite(parsedValue)) return;

            await updateZone(selectedZone, { dayMinutesLimit: parsedValue });
          }}
        />
        <TextInput
          label="Минуты в неделю"
          value={selectedZone.weekMinutesLimit?.toString() ?? ""}
          onChange={async (weekMinutesLimit) => {
            if (weekMinutesLimit === "")
              return await updateZone(selectedZone, { weekMinutesLimit: null });

            const parsedValue = Number.parseInt(weekMinutesLimit, 10);
            if (!Number.isFinite(parsedValue)) return;

            await updateZone(selectedZone, { weekMinutesLimit: parsedValue });
          }}
        />
        <button
          className={styles.deleteButton}
          onClick={async () => await deleteZone(selectedZone)}
        >
          Удалить
        </button>
      </div>
    </Panel>
  );
}
