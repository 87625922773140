import { clsx } from "clsx";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { BsCheckSquare, BsSquare } from "react-icons/bs";

import { completeTask } from "entities/tasks";

import styles from "./task-checkbox.module.scss";

import { TaskContext } from "../model";

export function TaskCheckbox() {
  const { task } = useContext(TaskContext);
  if (!task || task.children.length !== 0) return;

  return (
    <motion.div tabIndex={-1} whileTap={{ scale: 1.3 }}>
      {task.isCompleted ? (
        <BsCheckSquare
          className={clsx(styles.checkbox)}
          onClick={async () => await completeTask(task.task, undefined)}
        />
      ) : (
        <BsSquare
          className={clsx(styles.checkbox)}
          onClick={async () => await completeTask(task.task, new Date())}
        />
      )}
    </motion.div>
  );
}
