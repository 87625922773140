import { useRenderCount } from "@react-hookz/web";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { BsPlus } from "react-icons/bs";

import { AddIntakeForm } from "features/add-calorie-intake/ui/add-intake-form";

import styles from "./add-intake-dialog.module.scss";

const TRANSITION_DURATION = 0.15;

export function AddIntakeDialog() {
  const renderCount = useRenderCount();

  const [isOpen, setIsOpen] = useState(false);

  useHotkeys("Meta+I", () => setIsOpen(true));

  return (
    <div className={styles.container}>
      <motion.div
        layout
        initial={false}
        animate={{
          borderRadius: isOpen ? "10px" : "50%",
        }}
        transition={{
          duration: TRANSITION_DURATION,
          delay: isOpen ? 0 : TRANSITION_DURATION,
          ease: "circInOut",
          borderRadius: {
            delay: isOpen ? 0 : TRANSITION_DURATION * 1.8,
            duration: isOpen ? TRANSITION_DURATION : TRANSITION_DURATION * 0.2,
          },
        }}
        style={{
          boxShadow: "0 0px 9px 4px #E6E6E6",
        }}
      >
        <AnimatePresence mode="popLayout">
          {isOpen ? (
            <motion.div
              key="form"
              layout="position"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: TRANSITION_DURATION },
              }}
              exit={{
                opacity: 0,
                transition: { duration: TRANSITION_DURATION },
              }}
            >
              <AddIntakeForm onClose={() => setIsOpen(false)} />
            </motion.div>
          ) : (
            <motion.div
              key="openButton"
              className={styles.openButton}
              initial={renderCount === 1 ? false : { opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: TRANSITION_DURATION * 0.2,
                  delay: TRANSITION_DURATION * 1.8,
                },
              }}
              exit={{
                opacity: 0,
                transition: { duration: TRANSITION_DURATION * 0.2 },
              }}
              onClick={() => setIsOpen(true)}
            >
              <BsPlus />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}
