import { offset, useFloating } from "@floating-ui/react";
import { useClickOutside } from "@react-hookz/web";
import { motion } from "framer-motion";
import React, { ReactNode } from "react";
import { createPortal } from "react-dom";

import { Panel } from "shared/ui/panel";

interface PopupMenuProps {
  targetElement: HTMLElement | null;
  children: ReactNode;
  verticalOffset?: number;
  onClickOutside?: () => void;
  className?: string;
}

export function Popover({
  targetElement,
  children,
  verticalOffset,
  onClickOutside,
  className,
}: PopupMenuProps) {
  const { refs, floatingStyles } = useFloating({
    elements: {
      reference: targetElement,
    },
    middleware: [offset(verticalOffset ?? 12)],
  });

  useClickOutside(refs.floating, (e) => {
    if (
      targetElement === e.target ||
      (e.target instanceof Node && targetElement?.contains(e.target))
    )
      return;
    onClickOutside?.();
  });

  return createPortal(
    <>
      <div ref={refs.setFloating} style={floatingStyles} className={className}>
        <motion.div
          initial={{ opacity: 0.7, scale: 0.7 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <Panel>{children}</Panel>
        </motion.div>
      </div>
    </>,
    document.body,
  );
}
