import { CodeNode } from "@lexical/code";
import { LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from "@lexical/markdown";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { usePrevious } from "@react-hookz/web";
import { clsx } from "clsx";
import { AnimatePresence, Transition, motion } from "framer-motion";
import { COMMAND_PRIORITY_CRITICAL, KEY_ENTER_COMMAND } from "lexical";
import pLimit from "p-limit";
import React, { useEffect, useState } from "react";

import { TaskModel, updateTask } from "entities/tasks";

import { TaskEntity } from "shared/database";

import styles from "./task-notes.module.scss";

const updateTaskLimit = pLimit(1);

async function throttledUpdateTask(task: TaskEntity, notes: string) {
  updateTaskLimit.clearQueue();
  await updateTaskLimit(() => updateTask(task, { notes }));
}

export function TaskNotes({
  task,
  isEditing,
  className,
}: {
  task: TaskModel;
  isEditing: boolean;
  className?: string;
}) {
  const [notes, setNotes] = useState<string>(task?.task.notes ?? "");

  useEffect(() => {
    if (!task) return;
    void throttledUpdateTask(task.task, notes);
  }, [notes, task]);

  const prevIsEditing = usePrevious(isEditing);

  const transition: Transition = {
    duration: 0.4,
    ease: "circOut",
    backgroundColor: {
      ease: "circOut",
      duration: prevIsEditing !== isEditing ? 0.4 : 0,
    },
  };

  return (
    <AnimatePresence>
      {isEditing && (
        <motion.div
          variants={{
            default: { height: 0, opacity: 0, margin: 0 },
            selected: { height: 0, opacity: 0, margin: 0 },
            editing: { height: "max-content", opacity: 1 },
          }}
          exit={"default"}
          transition={{
            ...transition,
            opacity: { duration: transition.duration / 2 },
          }}
          className={clsx(styles.notes, className)}
        >
          <LexicalComposer
            initialConfig={{
              namespace: "Task notes",
              onError: console.error,
              nodes: [
                HeadingNode,
                QuoteNode,
                CodeNode,
                ListNode,
                ListItemNode,
                LinkNode,
              ],
              editorState: () =>
                $convertFromMarkdownString(
                  task.task.notes ?? "",
                  TRANSFORMERS,
                  undefined,
                  true,
                ),
              theme: {
                text: {
                  bold: styles.bold,
                  italic: styles.italic,
                  underline: styles.underline,
                },
              },
            }}
          >
            <RichTextPlugin
              contentEditable={<ContentEditable className={styles.content} />}
              placeholder={<div className={styles.placeholder}>Заметки</div>}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <PreventEnterPropagationPlugin />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <OnChangePlugin
              onChange={(editorState) => {
                editorState.read(() => {
                  setNotes(
                    $convertToMarkdownString(TRANSFORMERS, undefined, true),
                  );
                });
              }}
            />
            <ClickableLinkPlugin />
            <ListPlugin />
            <HistoryPlugin />
          </LexicalComposer>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function PreventEnterPropagationPlugin() {
  const [editor] = useLexicalComposerContext();

  editor.registerCommand(
    KEY_ENTER_COMMAND,
    (e) => {
      if (e !== null) {
        if (e.metaKey) return true;
        else e.stopPropagation();
      }
      return false;
    },
    COMMAND_PRIORITY_CRITICAL,
  );

  return null;
}
