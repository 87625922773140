import { v4 as uuid } from "uuid";

import { DefaultColor, ZoneEntity, database } from "shared/database";

export async function updateZone(
  zone: ZoneEntity,
  changes: {
    name?: string;
    color?: string;
    dayMinutesLimit?: number | null;
    weekMinutesLimit?: number | null;
  },
) {
  await database.zones.update(zone.id, changes);
}

export async function deleteZone(zone: ZoneEntity) {
  await database.zones.delete(zone.id);
}

export async function createEmptyZone() {
  await database.zones.add({
    id: uuid(),
    name: "Новая зона",
    color: DefaultColor,
    dayMinutesLimit: null,
    weekMinutesLimit: null,
  });
}
