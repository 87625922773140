import {
  Edge,
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useRenderCount } from "@react-hookz/web";
import { clsx } from "clsx";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { BsArrowRepeat } from "react-icons/bs";
import { MdCallMerge, MdCallSplit } from "react-icons/md";

import { TaskModel } from "entities/tasks/model/task-model";
import { ZonesApi } from "entities/zones/model/use-zones";

import styles from "./task.module.scss";

interface PrioritizedTaskProps {
  task: TaskModel;
  zonesApi: ZonesApi;
  onSplit: () => void;
  onMerge?: () => void;
  onPrioritize: (target: TaskModel, position: "before" | "after") => void;
}

export function Task({
  task,
  zonesApi,
  onPrioritize,
  onSplit,
  onMerge,
}: PrioritizedTaskProps) {
  const ref = useRef<HTMLDivElement>(null);

  const renderCount = useRenderCount();

  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    return combine(
      draggable({
        element: ref.current,
        getInitialData: () => ({
          type: "task",
          height: ref.current!.getBoundingClientRect().height ?? 0,
          task,
          groupId: task.task.priorityGroupId ?? "default",
        }),
        onDrop: ({ location }) => {
          const destination = location.current.dropTargets[0];

          if (destination.data.type === "task") {
            onPrioritize?.(
              destination.data.task as TaskModel,
              extractClosestEdge(destination.data) === "top"
                ? "before"
                : "after",
            );
          }
        },
      }),
      dropTargetForElements({
        element: ref.current,
        canDrop: ({ source }) => source.data.type === "task",
        getIsSticky: () => true,
        getData: ({ input, element }) =>
          attachClosestEdge(
            { type: "task", task },
            {
              input,
              element,
              allowedEdges: ["top", "bottom"],
            },
          ),
        onDrag: ({ self }) => {
          setClosestEdge(extractClosestEdge(self.data));
        },
        onDragEnter: ({ self }) => {
          setClosestEdge(extractClosestEdge(self.data));
        },
        onDragLeave: () => setClosestEdge(null),
        onDrop: () => setClosestEdge(null),
      }),
    );
  }, [setClosestEdge, task, onPrioritize, closestEdge]);

  return (
    <motion.div
      ref={ref}
      layout
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { delay: renderCount === 1 ? 0 : 0.3 },
      }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <div className={styles.task}>
        <div className={styles.controls}>
          <MdCallSplit
            onClick={onSplit}
            className={clsx(styles.split, {
              [styles.hidden]: task.children.length === 0,
            })}
          />
          <MdCallMerge
            onClick={onMerge}
            className={clsx(styles.merge, {
              [styles.hidden]: !task.task.priority && !task.parent,
            })}
          />
        </div>
        <div
          className={styles.zone}
          style={{
            backgroundColor: !task.zoneId
              ? "transparent"
              : zonesApi.tryGetById(task.zoneId)!.color,
          }}
        />
        <div className={styles.details}>
          <span
            className={clsx(styles.name, {
              [styles.completed]: task.isCompleted,
            })}
          >
            {task.task.name}
          </span>
          {task.parent && (
            <div
              className={clsx(styles.parent, {
                [styles.completed]: task.isCompleted,
              })}
            >
              {task.parent.title}
            </div>
          )}
          <BsArrowRepeat
            className={clsx(styles.repeat, {
              [styles.available]:
                task.children.length === 0 &&
                task.task.plan?.type === "recurring",
            })}
          />
        </div>
      </div>
      {closestEdge && <DropIndicator edge={closestEdge} />}
    </motion.div>
  );
}
