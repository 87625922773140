import React, { useState } from "react";

import styles from "./color-input.module.scss";

interface InputProps {
  label: string;
  color: string;
  onChange: (color: string) => void;
}

export function ColorInput({ label, color, onChange }: InputProps) {
  const [isChoosingColor, setIsChoosingColor] = useState(false);

  const predefinedColors = [
    "hsl(202, 100%, 63%)",
    "hsl(355, 98%, 66%)",
    "hsl(30, 0%, 57%)",
    "hsl(268, 100%, 72%)",
    "hsl(174, 53%, 52%)",
    "hsl(84, 63%, 56%)",
    "hsl(189, 71%, 55%)",
    "hsl(258, 90%, 73%)",
    "hsl(140, 66%, 52%)",
    "hsl(43, 91%, 58%)",
    "hsl(24, 99%, 60%)",
    "hsl(330, 100%, 68%)",
    "hsl(37, 93%, 60%)",
    "hsl(345, 100%, 67%)",
    "hsl(239, 84%, 73%)",
    "hsl(292, 84%, 68%)",
  ];

  return (
    <div className={styles.input}>
      <label className={styles.label}>{label}</label>
      <div className={styles.control}>
        {!isChoosingColor && (
          <div
            className={styles.color}
            style={{ backgroundColor: color }}
            onClick={() => setIsChoosingColor(true)}
          />
        )}
        {isChoosingColor &&
          predefinedColors.map((predefinedColor) => (
            <div
              key={predefinedColor}
              className={styles.color}
              style={{ backgroundColor: predefinedColor }}
              onClick={() => {
                onChange(predefinedColor);
                setIsChoosingColor(false);
              }}
            />
          ))}
      </div>
    </div>
  );
}
