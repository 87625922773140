import { clsx } from "clsx";
import { motion } from "framer-motion";
import React, { ReactNode, forwardRef } from "react";
import { range } from "remeda";

import { TaskModel } from "entities/tasks/model/task-model";

import styles from "./task.module.scss";

import { TaskContext } from "../model";

interface TaskProps {
  task: TaskModel;
  level: number;
  isSelected: boolean;
  isEditing?: boolean;
  onEditToggle?: () => void;
  className?: string;
  children: ReactNode;
}

export const Task = forwardRef<HTMLDivElement, TaskProps>(
  (
    {
      task,
      level,
      isSelected,
      isEditing,
      children,
      onEditToggle,
      className,
    }: TaskProps,
    ref,
  ) => {
    return (
      <TaskContext.Provider
        value={{
          task,
          isEditing,
          onEditToggle,
        }}
      >
        <div
          ref={ref}
          className={clsx(styles.task, className)}
          style={{
            gridTemplateColumns: `repeat(${level}, 2rem) 1fr`,
          }}
        >
          {range(0, level).map((i) => (
            <div key={i} className={styles.verticalLine} />
          ))}
          <motion.div
            layout="position"
            layoutId={`task-${task.id}`}
            style={{
              zIndex: isSelected ? 1 : 0,
            }}
          >
            {children}
          </motion.div>
        </div>
      </TaskContext.Provider>
    );
  },
);

Task.displayName = "Task";
