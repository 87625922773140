import React, { ReactNode } from "react";

import { DynamicTag, StaticTag } from "./tag";
import { TagsContext } from "./tags-context";
import styles from "./tags.module.scss";

interface TagsProps<T> {
  value: T;
  onChange: (value: T) => void;
  onAdd?: () => void;
  children: ReactNode;
}

const Tags = <T,>({ value, onChange, onAdd, children }: TagsProps<T>) => {
  return (
    <TagsContext.Provider value={{ value: value as never, onChange }}>
      <div className={styles.buttons}>
        {children}
        {onAdd && <StaticTag onClick={onAdd}>+</StaticTag>}
      </div>
    </TagsContext.Provider>
  );
};

export default Object.assign(Tags, {
  Tag: DynamicTag,
});
