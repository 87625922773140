import Dexie, { EntityTable } from "dexie";

interface PriorityGroupEntity {
  id: string;
  name: string;
  order: number;
}

interface CalorieIntakeEntity {
  id: string;
  calories: number;
  note: string;
  creationDateTimeUtc: Date;
}

interface BaseSession {
  id: string;
  completedAt?: Date;
}

export type FlexibleSession = BaseSession & {
  type: "flexible";
};

export type TimeBoundSession = BaseSession & {
  type: "timeBound";
  startDate: string;
  days: number;
};

export type Session = FlexibleSession | TimeBoundSession;

export interface FlexiblePlan {
  type: "flexible";
  sessions: [Session];
}

export interface RecurringPlan {
  type: "recurring";
  rrule: string;
  groupType: "day" | "week" | "month" | "year";
  sessions: TimeBoundSession[];
}

export type Plan = FlexiblePlan | RecurringPlan;

export interface BaseTaskEntity {
  id: string;
  name: string;
  notes?: string;
  order: number;
  parentId: string | null;
  duration: number;
  collapsed: boolean;
  priority?: number;
  priorityGroupId?: string;
  zoneId?: string;
  plan?: Plan;
  completedAt?: Date;
}

export type LocatedTaskEntity<TLocation extends string> = BaseTaskEntity & {
  location: TLocation;
};

export type InboxTaskEntity = LocatedTaskEntity<"inbox">;
export type OutlineTaskEntity = LocatedTaskEntity<"outline">;
export type DeletedTaskEntity = LocatedTaskEntity<"trash"> & { deleted: true };
export type JournalTaskEntity = LocatedTaskEntity<"journal"> & {
  loggedAt: Date;
};

export type TaskEntity =
  | InboxTaskEntity
  | OutlineTaskEntity
  | DeletedTaskEntity
  | JournalTaskEntity;

export interface EventEntity {
  id: string;
  taskId: string;
  sessionId: string;
  isPinned: boolean;
  startDate: Date;
  duration: number;
  actualDuration?: number;
}

export const DefaultColor = `hsl(221, 81%, 63%)`;

export interface ZoneEntity {
  id: string;
  name: string;
  color: string;
  dayMinutesLimit: number | null;
  weekMinutesLimit: number | null;
}

export const database = new Dexie("PlanBalanceDatabase") as Dexie & {
  tasks: EntityTable<TaskEntity, "id">;
  events: EntityTable<EventEntity, "id">;
  zones: EntityTable<ZoneEntity, "id">;
  priorityGroups: EntityTable<PriorityGroupEntity, "id">;
};

database.version(51).stores({
  tasks:
    "++id,name,order,parentId,priority,zoneId,location,plan.type,loggedAt,completedAt",
  events: "id,taskId,sessionId,startDate,completedAt",
  zones: "id,name,color",
  priorityGroups: "id,order",
  calorieIntakes: "id,creationDateTimeUtc",
});
