import { DateTime } from "luxon";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import { database } from "shared/database";

export function useEvents() {
  const pin = useCallback(
    async (id: string, startDateTime: DateTime, duration: number) =>
      database.events.update(id, {
        isPinned: true,
        startDate: startDateTime.toJSDate(),
        duration: duration,
      }),
    [],
  );

  const replace = useCallback(
    async (
      events: {
        id?: string;
        isPinned: boolean;
        taskId: string;
        sessionId: string;
        day: DateTime;
        startMinute: number;
        endMinute: number;
      }[],
    ) => {
      await database.transaction("readwrite", database.events, async () => {
        await database.events.filter((event) => !event.actualDuration).delete();

        await database.events.bulkAdd(
          events.map((event) => ({
            id: event.id ?? uuidv4(),
            taskId: event.taskId,
            sessionId: event.sessionId,
            startDate: event.day.plus({ minute: event.startMinute }).toJSDate(),
            duration: event.endMinute - event.startMinute,
            isPinned: event.isPinned,
          })),
        );
      });
    },
    [],
  );

  return {
    replace,
    pin,
  };
}
