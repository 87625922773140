import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { preventUnhandled } from "@atlaskit/pragmatic-drag-and-drop/prevent-unhandled";
import React, { useEffect, useState } from "react";

import { TextInput } from "shared/ui/text-input";

import styles from "./add-intake-form.module.scss";

interface AddIntakeFormProps {
  onClose: () => void;
}

export function AddIntakeForm({ onClose }: AddIntakeFormProps) {
  const [intakeElement, setIntakeElement] = useState<HTMLDivElement | null>();

  const [caloriesPer100Grams, setCaloriesPer100Grams] = useState(0);
  const [weightInGrams, setWeightInGrams] = useState(0);

  const calories = (weightInGrams * caloriesPer100Grams) / 100;

  useEffect(() => {
    if (!intakeElement) return;

    return draggable({
      element: intakeElement,
      getInitialData: () => ({
        calories,
      }),
      onDragStart: () => {
        preventUnhandled.start();
        onClose();
      },
    });
  }, [intakeElement]);

  return (
    <div
      className={styles.form}
      onKeyDown={(e) => e.key === "Escape" && onClose()}
    >
      <TextInput
        label="Калории на 100 грамм"
        value={caloriesPer100Grams.toString()}
        onChange={(v) => setCaloriesPer100Grams(Number.parseFloat(v))}
        autoFocus
      />
      <TextInput
        label="Вес в граммах"
        value={weightInGrams.toString()}
        onChange={(v) => setWeightInGrams(Number.parseFloat(v))}
      />
      <div
        className={styles.intake}
        ref={setIntakeElement}
        onClick={() => onClose()}
      >
        {calories} ккал
      </div>
    </div>
  );
}
