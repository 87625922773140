import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { useLocalStorageValue } from "@react-hookz/web";
import { clsx } from "clsx";
import { motion, useMotionValue, useTransform } from "framer-motion";
import React, { Children, useEffect, useState } from "react";

import styles from "./layout.module.scss";

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const mappedChildren = Children.toArray(children);

  const { value: defaultPanelsProportion, set: setDefaultPanelsProportion } =
    useLocalStorageValue("LayoutPanelsProportion", { defaultValue: 1.0 / 3 });

  const panelsProportion = useMotionValue(defaultPanelsProportion ?? 0);
  const gridTemplateColumns = useTransform(
    panelsProportion,
    (p) => `${p}fr 0 ${1 - p}fr`,
  );

  const [leftPanelElement, setLeftPanelElement] =
    useState<HTMLDivElement | null>(null);

  const [rightPanelElement, setRightPanelElement] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!leftPanelElement || !rightPanelElement) return;

    return combine(
      autoScrollForElements({
        element: rightPanelElement,
      }),
      autoScrollForElements({
        element: leftPanelElement,
      }),
    );
  }, [leftPanelElement, rightPanelElement]);

  if (mappedChildren.length !== 2) throw new Error("Ожидается 2 элемента.");

  return (
    <div className={styles.safeContainer}>
      <motion.div className={styles.layout} style={{ gridTemplateColumns }}>
        <motion.div
          layoutScroll
          ref={setLeftPanelElement}
          className={styles.leftPanel}
        >
          {mappedChildren[0]}
        </motion.div>
        <motion.div
          layoutScroll
          ref={setRightPanelElement}
          className={styles.rightPanel}
        >
          {mappedChildren[1]}
        </motion.div>
        <motion.div
          className={styles.divider}
          onPan={(_, i) =>
            panelsProportion.set(
              panelsProportion.get() + i.delta.x / document.body.clientWidth,
            )
          }
          onPanEnd={() => setDefaultPanelsProportion(panelsProportion.get())}
        >
          <div
            className={clsx(styles.bar, {
              [styles.inTestEnvironment]: import.meta.env.DEV,
            })}
          />
        </motion.div>
      </motion.div>
    </div>
  );
}
