import React, { useState } from "react";

import { createEmptyZone, useZones } from "entities/zones";

import { Tags } from "shared/ui";

import { ZoneInfo } from "./zone-info";
import styles from "./zones.module.scss";

export function Zones() {
  const { zones } = useZones();

  const [selectedZoneId, setSelectedZoneId] = useState<string | undefined>(
    zones[0]?.id,
  );

  const selectedZone = zones.find((z) => z.id === selectedZoneId) ?? zones[0];

  return (
    <div className={styles.zones}>
      <Tags
        value={selectedZone?.id}
        onChange={setSelectedZoneId}
        onAdd={async () => await createEmptyZone()}
      >
        {zones.map((zone) => (
          <Tags.Tag key={zone.id} value={zone.id}>
            {zone.name}
          </Tags.Tag>
        ))}
      </Tags>
      <div className={styles.zone}>
        <div>{selectedZone && <ZoneInfo selectedZone={selectedZone} />}</div>
      </div>
    </div>
  );
}
