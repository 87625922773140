import { DateTime } from "luxon";
import invariant from "tiny-invariant";

import { completeTaskSession } from "entities/tasks";

import { EventEntity, database } from "shared/database";

export async function clearEventsWithoutSessions() {
  await database.transaction(
    "readwrite",
    [database.events, database.tasks],
    async () => {
      const tasks = await database.tasks.toArray();
      const taskIds = tasks.map((t) => t.id);
      const taskSessionIds = tasks.flatMap(
        (t) => t.plan?.sessions.map((s) => s.id) ?? [],
      );

      await database.events
        .filter(
          (e) =>
            !taskIds.includes(e.taskId) ||
            !taskSessionIds.includes(e.sessionId),
        )
        .delete();
    },
  );
}

export async function completeEvent(
  { id: eventId }: EventEntity,
  isCompleted: boolean,
) {
  await database.transaction(
    "readwrite",
    [database.events, database.tasks],
    async () => {
      const event = await database.events.get(eventId);
      if (!event) throw new Error(`Can't find event with id ${eventId}`);

      const task = await database.tasks.get(event.taskId);
      if (!task) throw new Error(`Can't find task with id ${event.taskId}`);

      const session = task.plan?.sessions.find((s) => s.id === event.sessionId);
      if (!session)
        throw new Error(`Cannot find session with id ${event.sessionId}`);

      await completeTaskSession(
        task,
        session,
        isCompleted ? new Date() : undefined,
      );
      await markEventAsCompleted(event, isCompleted);
    },
  );
}

export async function markEventAsCompleted(
  { id: eventId }: EventEntity,
  isCompleted: boolean,
) {
  await database.transaction(
    "readwrite",
    [database.events, database.tasks],
    async () => {
      const event = await database.events.get(eventId);
      if (!event) throw new Error(`Can't find event with id ${eventId}`);

      const actualDuration = estimateActualDuration();
      await database.events.update(eventId, { actualDuration });

      function estimateActualDuration() {
        invariant(event);

        if (!isCompleted) return undefined;

        const startDateTime = DateTime.fromJSDate(event.startDate);
        const estimatedDuration = event.duration;

        if (DateTime.now() >= startDateTime)
          return Math.min(
            DateTime.now().diff(startDateTime, "minutes").minutes,
            estimatedDuration,
          );

        return estimatedDuration;
      }
    },
  );
}
