import React from "react";
import { BsListTask } from "react-icons/bs";
import { LiaUtensilsSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

import { CaloriesCalendar } from "widgets/calories-calendar";

import { ExportButton, ImportButton } from "entities/tasks";

import { routePaths } from "shared/router";
import { ControlPanel, Layout } from "shared/ui";

export function MealsPage() {
  const navigate = useNavigate();

  return (
    <div>
      <Layout>
        <div>Meals</div>
        <CaloriesCalendar />
      </Layout>
      <ControlPanel>
        <ControlPanel.Divider />
        <ExportButton />
        <ImportButton />
        <ControlPanel.Divider />
        <ControlPanel.Button
          icon={BsListTask}
          onClick={() => navigate(routePaths.TASKS)}
          hotkey="Meta+T"
        />
        <ControlPanel.Button
          icon={LiaUtensilsSolid}
          onClick={() => navigate(routePaths.MEALS)}
        />
      </ControlPanel>
    </div>
  );
}
