import { useCallback, useEffect } from "react";

const keyboardEventListeners = new Set<(e: KeyboardEvent) => void>();

window.addEventListener("keydown", (e: KeyboardEvent) =>
  keyboardEventListeners.forEach((l) => l(e)),
);

interface UseKeyboardShortcutProps {
  key: string;
  metaKey?: boolean;
  shiftKey?: boolean;
  ctrlKey?: boolean;
  preventDefault?: boolean;
}

export function useKeyboardShortcut(
  { key, metaKey, shiftKey, ctrlKey, preventDefault }: UseKeyboardShortcutProps,
  callback: () => void,
) {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key.toUpperCase() !== key.toUpperCase()) return;
      if ((metaKey ?? false) !== e.metaKey) return;
      if ((shiftKey ?? false) !== e.shiftKey) return;
      if ((ctrlKey ?? false) !== e.ctrlKey) return;
      if (preventDefault ?? true) e.preventDefault();

      callback();
    },
    [callback, key, metaKey, preventDefault, shiftKey, ctrlKey],
  );

  useEffect(() => {
    keyboardEventListeners.add(handleKeyDown);
    return () => {
      keyboardEventListeners.delete(handleKeyDown);
    };
  }, [handleKeyDown]);
}
