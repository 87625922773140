import { DateTime } from "luxon";
import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { range } from "remeda";

import { CalendarWeek } from "./calendar-week";
import styles from "./calendar.module.scss";

export function Calendar() {
  const [startDay, setStartDay] = useState(DateTime.now().startOf("day"));
  const [visibleDays, setVisibleDays] = useState(5);

  useHotkeys("j", () => setStartDay((prev) => prev.plus({ day: 1 })));
  useHotkeys("k", () => setStartDay((prev) => prev.plus({ day: -1 })));
  useHotkeys("r", () => setStartDay(DateTime.now().startOf("day")));

  useHotkeys(
    range(0, 10).map((i) => `d+${i}`),
    (e) => {
      if (e.key === "0") setVisibleDays(14);
      else setVisibleDays(Number.parseInt(e.key, 10));
    },
  );

  return (
    <div className={styles.calendar}>
      <h2 className={styles.date}>
        <span className={styles.month}>
          {startDay.toLocaleString({ month: "long" }, { locale: "ru" })}
        </span>
        <span>{` ${startDay.year}`}</span>
      </h2>
      <CalendarWeek startDay={startDay} days={visibleDays} />
    </div>
  );
}
