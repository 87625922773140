import { importInto } from "dexie-export-import";
import React, { useCallback } from "react";
import { BsCloudArrowUpFill } from "react-icons/bs";

import { database } from "shared/database";
import { ControlPanel } from "shared/ui/control-panel";

export function ImportButton() {
  const importDatabase = useCallback(async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.click();

    input.addEventListener("change", async () => {
      if (!input.files || input.files.length !== 1) return;

      const file = input.files[0];
      await importInto(database, file, { clearTablesBeforeImport: true });
    });
  }, []);

  return (
    <ControlPanel.Button icon={BsCloudArrowUpFill} onClick={importDatabase} />
  );
}
