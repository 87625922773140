import { DateTime } from "luxon";
import { groupBy, values } from "remeda";
import invariant from "tiny-invariant";

import { TaskModel } from "entities/tasks/model";

import { EventEntity, Session, ZoneEntity } from "shared/database";
import { roundMinutesToSlot } from "shared/libs/time";

import { Schedule } from "./schedule";

type SchedulerSession = Session & {
  task: TaskModel;
};

export class Scheduler {
  constructor(
    private readonly sessions: SchedulerSession[],
    private readonly zones: (ZoneEntity & {
      actualDayMinutes: Map<string, number>;
    })[],
    private readonly pinnedEvents: EventEntity[],
  ) {}

  public schedule(
    todayStartSlot: number,
    startDaySlot: number,
    endDaySlot: number,
  ) {
    const startOfToday = DateTime.now().startOf("day");

    const schedule = new Schedule(
      todayStartSlot * 15,
      startDaySlot * 15,
      endDaySlot * 15,
      this.zones,
      this.pinnedEvents.map((pe) => {
        const eventStartDateTime = DateTime.fromJSDate(pe.startDate);
        const eventStartDate = eventStartDateTime.startOf("day");
        const estimatedDuration = pe.duration;

        const duration = roundMinutesToSlot(
          pe.actualDuration ?? estimatedDuration,
        );

        const startMinute = eventStartDateTime.diff(
          eventStartDate,
          "minutes",
        ).minutes;

        if (!eventStartDate.isValid)
          throw new Error(`Invalid event start date ${eventStartDate}`);

        return {
          day: eventStartDate,
          sessionId: pe.sessionId,
          taskId: pe.taskId,
          startMinute,
          endMinute: startMinute + duration,
        };
      }),
    );

    function createOrMoveSessionEarlier(session: SchedulerSession) {
      const currentDay =
        session.type === "flexible"
          ? undefined
          : DateTime.max(startOfToday, DateTime.fromISO(session.startDate));

      const endDay =
        session.type === "flexible"
          ? undefined
          : currentDay?.plus({ day: session.days - 1 });

      const context = {
        session,
        zoneId: session.task.zoneId,
        taskId: session.task.id,
      };

      if (schedule.hasScheduledSession(session)) {
        return schedule.tryRescheduleSessionEarlier(
          context,
          currentDay,
          endDay,
        );
      } else {
        return schedule.tryReserveEarliestEvent(
          context,
          roundMinutesToSlot(session.task.duration),
          currentDay,
          endDay,
        );
      }
    }

    for (const sessionGroup of this.getTimeBoundSessionGroups()) {
      invariant(sessionGroup.every((s) => s.type === "timeBound"));

      for (const session of sessionGroup) {
        const event = createOrMoveSessionEarlier(session);
        if (!event)
          console.error("Unable to schedule earliest session", session);
      }

      for (const session of sessionGroup.reverse()) {
        const currentDay = DateTime.fromISO(session.startDate);
        const endDay = currentDay.plus({ day: session.days - 1 });

        const context = {
          session,
          zoneId: session.task.zoneId,
          taskId: session.task.id,
        };

        const event = schedule.tryRescheduleSessionLater(
          context,
          currentDay,
          endDay,
        );

        if (!event)
          console.error("Unable to reschedule session later", session);
      }
    }

    for (const session of this.getSessionsWithoutPinnedEvents()) {
      const event = createOrMoveSessionEarlier(session);
      if (!event) console.error("Unable to schedule session", session);
    }

    return schedule.getScheduledEvents();
  }

  private getTimeBoundSessionGroups() {
    const timeBoundSessions = this.getSessionsWithoutPinnedEvents()
      .filter((s) => s.type === "timeBound")
      .map((session, index) => ({ session, index }));

    const sessionGroups: { session: SchedulerSession; index: number }[][] = [];

    for (const sessions of values(
      groupBy(timeBoundSessions, (s) => s.session.days),
    )) {
      const sessionGroup = (sessionGroups.slice(-1)[0] ?? [])
        .concat(sessions)
        .sort((a, b) => a.index - b.index);

      sessionGroups.push(sessionGroup);
    }

    return sessionGroups.map((sg) => sg.map((s) => s.session));
  }

  private getSessionsWithoutPinnedEvents() {
    const sessionIdsWithPinnedEvents = new Set(
      this.pinnedEvents.map((pe) => pe.sessionId),
    );

    return this.sessions.filter((s) => !sessionIdsWithPinnedEvents.has(s.id));
  }
}
