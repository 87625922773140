import React from "react";

import styles from "./panel.module.scss";

interface PanelProps {
  children: React.ReactNode;
}

export function Panel({ children }: PanelProps) {
  return <div className={styles.panel}>{children}</div>;
}
