import React, { ReactNode } from "react";

import { Tags } from "shared/ui";

interface OptionProps<T> {
  activeColor?: string;
  value: T;
  children: ReactNode;
}

export function Option<T>({ activeColor, value, children }: OptionProps<T>) {
  return (
    <Tags.Tag activeColor={activeColor} value={value}>
      {children}
    </Tags.Tag>
  );
}
