import {
  Instruction,
  attachInstruction,
  extractInstruction,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { $rootTextContent } from "@lexical/text";
import { clsx } from "clsx";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

import { OnExitPlugin, PreventEnterPlugin } from "shared/libs/lexical";

import styles from "./group.module.scss";

interface PriorityGroupProps {
  id: string;
  name: string;
  isDefault?: boolean;
  onRename?: (name: string) => void;
  onDelete?: () => void;
  isCollapsed: boolean;
  onCollapse?: () => void;
  children: React.ReactNode;
}

export function Group({
  id,
  name,
  isDefault,
  onRename,
  onDelete,
  isCollapsed,
  onCollapse,
  children,
}: PriorityGroupProps) {
  const [groupElement, setGroupElement] = useState<HTMLDivElement | null>(null);
  const [groupTitleElement, setGroupHeaderElement] =
    useState<HTMLDivElement | null>(null);

  const [isEditing, setIsEditing] = useState(false);

  const [instruction, setInstruction] = useState<Instruction | null>(null);

  useEffect(() => {
    if (!groupTitleElement || !groupElement) return;

    return combine(
      draggable({
        element: groupTitleElement,
        canDrag: () => !isDefault,
        getInitialData: () => ({
          type: "group",
          groupId: id,
        }),
      }),
      dropTargetForElements({
        element: groupTitleElement,
        canDrop: ({ source }) => !isDefault && source.data.groupId !== id,
        getIsSticky: () => true,
        getData: ({ input, element, source }) =>
          attachInstruction(
            {
              sourceType: source.data.type,
              targetType: "group",
              groupId: id,
            },
            {
              input,
              element,
              currentLevel: 0,
              indentPerLevel: 20,
              mode: "standard",
              block: source.data.type === "task" ? [] : ["make-child"],
            },
          ),
        onDrag: ({ self }) => {
          const extractedInstruction = extractInstruction(self.data);
          setInstruction(extractedInstruction);
        },
        onDragEnter: ({ self }) => {
          const extractedInstruction = extractInstruction(self.data);
          setInstruction(extractedInstruction);
        },
        onDragLeave: () => setInstruction(null),
        onDrop: () => setInstruction(null),
      }),
    );
  }, [groupTitleElement, groupElement, isDefault, id]);

  return (
    <div ref={setGroupElement} className={styles.group}>
      <div
        ref={setGroupHeaderElement}
        className={styles.header}
        onClick={(e) => {
          if (e.metaKey && onRename) setIsEditing(true);
          else onCollapse?.();
        }}
      >
        {isCollapsed ? (
          <IoIosArrowForward className={styles.arrow} />
        ) : (
          <IoIosArrowDown className={styles.arrow} />
        )}
        <div className={clsx(styles.title, { [styles.isEmpty]: !name })}>
          {isEditing ? (
            <LexicalComposer
              initialConfig={{
                namespace: "Group title",
                onError: console.error,
                editorState: () => {
                  const paragraph = $createParagraphNode();
                  paragraph.append($createTextNode(name));
                  $getRoot().append(paragraph);
                  $getRoot().selectEnd();
                },
              }}
            >
              <PlainTextPlugin
                contentEditable={
                  <ContentEditable
                    className={styles.content}
                    onBlur={() => setIsEditing(false)}
                  />
                }
                placeholder={
                  <div className={styles.placeholder}>Название группы</div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <PreventEnterPlugin />
              <OnExitPlugin onExit={() => setIsEditing(false)} />
              <OnChangePlugin
                onChange={(editorState) => {
                  editorState.read(() => {
                    onRename?.($rootTextContent());
                  });
                }}
              />
              <HistoryPlugin />
            </LexicalComposer>
          ) : name ? (
            name
          ) : (
            "Группа без названия"
          )}
        </div>
        {onDelete && (
          <FaTrash
            className={styles.trash}
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
        )}
      </div>
      {!isCollapsed && <div className={styles.tasks}>{children}</div>}
      {instruction && <DropIndicator instruction={instruction} />}
    </div>
  );
}
