import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  BsFillPieChartFill,
  BsListNested,
  BsListTask,
  BsSortUp,
} from "react-icons/bs";
import { LiaUtensilsSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

import { Calendar } from "features/calendar";
import { Outline } from "features/outline";
import { Priorities } from "features/priorities";
import { ScheduleButton } from "features/scheduler";
import { Zones } from "features/zones";

import { ExportButton, ImportButton, logCompletedTasks } from "entities/tasks";

import { routePaths } from "shared/router";
import { ControlPanel, Layout } from "shared/ui";

export function TasksPage() {
  const navigate = useNavigate();

  const [leftPanelElement, setLeftPanelElement] = useState<React.ReactElement>(
    <Outline />,
  );

  useHotkeys("Meta+Shift+Y", async () => {
    await logCompletedTasks();
  });

  return (
    <div>
      <Layout>
        {leftPanelElement}
        <Calendar />
      </Layout>
      <ControlPanel>
        <ControlPanel.Button
          onClick={() => setLeftPanelElement(<Outline />)}
          icon={BsListNested}
          label="План"
          hotkey="Meta+1"
        />
        <ControlPanel.Button
          onClick={() => setLeftPanelElement(<Priorities />)}
          icon={BsSortUp}
          label="Приоритеты"
          hotkey="Meta+2"
        />
        <ControlPanel.Button
          onClick={() => setLeftPanelElement(<Zones />)}
          icon={BsFillPieChartFill}
          label="Зоны"
          hotkey="Meta+3"
        />
        <ScheduleButton />
        <ControlPanel.Divider />
        <ExportButton />
        <ImportButton />
        <ControlPanel.Divider />
        <ControlPanel.Button
          icon={BsListTask}
          onClick={() => navigate(routePaths.TASKS)}
        />
        <ControlPanel.Button
          icon={LiaUtensilsSolid}
          onClick={() => navigate(routePaths.MEALS)}
          hotkey="Meta+M"
        />
      </ControlPanel>
    </div>
  );
}
